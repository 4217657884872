import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const responsibilityPage: React.FC<{}> = () => (
	<>
		<SEO title="Social responsibility" />
		<PrimaryLayout>
		<Container py="40px">
			<h1>Social Responsibility</h1>
				<p>
					Coming soon!
				</p>
		</Container>
		</PrimaryLayout>		
	</>
);

export default responsibilityPage;
